@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Work+Sans:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-family: "Work Sans",sans-serif;
    line-height: 1.65;
    letter-spacing: .004em;
    color: #5f5f5f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  /* border: solid 1px red; */
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: -webkit-flex;
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}
 
/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  background: transparent;
  margin-bottom: 30px;
}

.header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  /* max-width: 80%; */
  margin: auto;
  /* border-bottom: solid 1px rgb(209, 209, 209); */
  background-color: white;
}

.header.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  overflow: hidden;
  -webkit-animation: menu-slide-in .5s;
          animation: menu-slide-in .5s;
}

@-webkit-keyframes menu-slide-in {
  /* You could think of as "step 1" */
  0% {
    top: -200px;
  }
  /* You could think of as "step 2" */
  100% {
    top: 0;
  }
}

@keyframes menu-slide-in {
  /* You could think of as "step 1" */
  0% {
    top: -200px;
  }
  /* You could think of as "step 2" */
  100% {
    top: 0;
  }
}

h1 {
  margin-top: 0;
  margin-bottom: 20px;
}
.logo {
  display: -webkit-flex;
  display: flex;
  width: 150px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.logo img {
  width: 100%;
  height: auto;
}
.menu-toggle, .menu-close {
  height: 20px;
  position: fixed;
  top: 20px;
  right: 20px ;
  z-index: 1;
}

.side-menu {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 50%;
  background: #5f5f5f;
  width: 20%;
  padding: 5px;
  text-align: left;
  color: white;
  font-size: small;
  letter-spacing: 2px;
  display: none;
  z-index: 1;
}
.side-menu.mobile-menu-visible {
  display: block;

}

.side-menu ul {
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
}
@media screen and (min-width: 1100px) {
  .side-menu {
    display: block;
    height: auto;
    background: transparent;
    color: #5f5f5f;
    top: 40px;
  }

  .side-menu ul {
    list-style: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    padding-left: 0;
  }
  .side-menu ul li {
    margin: 0 5px;
  }

  .menu-toggle, .menu-close {
    display: none;
  }
}

.portfolio-item {
  width: 100%;
  font-size: smaller;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 3px;
  position: relative;
}
.portfolio-item img {
  width: 100%;
  height: auto;
}
.portfolio-item-info {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  opacity: 0;
  transition: opacity .5s ease-in;
}

.portfolio-item-title {
  opacity: 0;
  transition: opacity .25s ease-in;
}

.portfolio-item-info:hover {
  background-color: #5f5f5f;
  color: #ffffff;
  opacity: .9;
  transition: opacity .25s ease-in;
}

.portfolio-item-info:hover .portfolio-item-title {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.portfolio-item-image {}

.portfolio-item-image img {
  width: 100%;
}
.container {
  max-width: 80%;
  margin: auto
}
.container-header {
  max-width: 80%;
  margin: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
a {
  text-decoration: none;
}
.carousel-modal {
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 5% 10%;
}

.carousel-modal-background {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #787878e5;
}

.carousel .slide img {
    width: auto;
    max-width: 100%;
    height: 80vh;
    vertical-align: top;
    border: 0;
    object-fit: contain;
    object-position: center;
}
body {
    padding-top: 50px;
    padding-bottom: 500px;
}
